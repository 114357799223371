import React, {useState} from 'react'
import {Box, Button, Container, Grid, Modal, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'

import imgManifestLogo from '../assets/manifest-logo.png'
import imgNeighborhoods from '../assets/neighborhoods.png'
import imgModular from '../assets/modular.png'
import imgComposable from '../assets/composable.png'
import imgFederated from '../assets/federated.png'

import {modalStyle} from '../theme'

export default function ManifestNetwork() {
  const [helloModalOpen, setHelloModalOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <Container disableGutters maxWidth={false} sx={{background: '#fff'}}>
        <Box
          component="section"
          sx={{
            py: 6,
            pb: 0,
            px: 3,
            margin: 'auto',
          }}
        >
          <Grid container spacing={5} wrap="wrap">
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Box
                  component="img"
                  sx={{margin: 'auto', width: 150}}
                  src={imgManifestLogo}
                  alt="The Manifest Network"
                />
              </Box>

              <Typography
                component="h5"
                variant="p"
                align="center"
                sx={{mb: 6}}
              >
                The Manifest Network
              </Typography>
              <Typography variant="h3" align="center">
                Connecting Generations Of The Web
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            py: 6,
            pt: 0,
            px: 6,
            width: {xs: '100%', sm: '80%', lg: 1200},
            margin: 'auto',
          }}
        >
          <Grid container spacing={5} wrap="wrap">
            <Grid item xs={12}>
              <Typography component="p" variant="p">
                Lifted built <strong>Manifest</strong>, a Layer 0 network
                purposefully designed end-to-end for usability that is
                interoperable across generations of the web.{' '}
                <strong>Manifest</strong> empowers companies and developers of
                all backgrounds to combine plug-and-play Web 2.0 and Web3
                modules into custom solutions in minutes. This approach opens
                the door for today's markets to gain the value of decentralized
                technology without hiring expensive resources or learning a new
                programming language.{' '}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{maxWidth: 640, margin: '15px auto 0'}}>
            <Grid item container spacing={2} wrap="wrap" sx={{mt: 2}}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="tertiary"
                  sx={{
                    width: '100%',
                    maxWidth: {xs: '100%', sm: '70%', lg: 220},
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => navigate('/liftoff')}
                >
                  Build On Manifest
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="secondary"
                  sx={{
                    width: '100%',
                    maxWidth: {xs: '100%', sm: '70%', lg: 220},
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => setHelloModalOpen(true)}
                >
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Container>
        <Box
          component="section"
          sx={{
            pt: 6,
            pb: 2,
            px: 3,
          }}
        >
          <Container>
            <Grid container spacing={4} wrap="wrap">
              <Grid
                container
                item
                xs={12}
                md={5}
                alignContent={{xs: 'center', sm: 'flex-start'}}
                justifyContent={{xs: 'flex-start', md: 'flex-end'}}
              >
                <Box sx={{mr: 2, maxWidth: {xs: 200, md: '60%'}}}>
                  <Box
                    component="img"
                    src={imgNeighborhoods}
                    sx={{
                      maxWidth: '100%',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item container xs={12} md={6}>
                <Typography variant="h3">Neighborhoods</Typography>
                <Typography component="p" variant="p">
                  <strong>Manifest</strong> can have both Web 2.0 and Web3
                  sub-networks, "Neighborhoods", that can communicate.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>

      <Container>
        <Box
          component="section"
          sx={{
            pt: 6,
            pb: 0,
            px: 3,
          }}
        >
          <Grid container spacing={2} wrap="wrap">
            {[
              {
                heading: 'Modular',
                image: imgModular,
                imgMax: 90,
              },
              {
                heading: 'Composable',
                image: imgComposable,
                imgMax: 120,
              },
              {
                heading: 'Federated',
                image: imgFederated,
                imgMax: 120,
              },
            ].map(({heading, imgMax, image}, index) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  lg={4}
                  key={`build-product-${index}`}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    lg={12}
                    className="pattern-3"
                    sx={{
                      p: 2,
                      height: {xs: 'auto', lg: 200},
                      borderTopLeftRadius: {xs: 20},
                      borderTopRightRadius: {xs: 20, md: 0, lg: 20},
                      borderBottomLeftRadius: {xs: 0, md: 20, lg: 0},
                    }}
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Box
                      component="img"
                      src={image}
                      sx={{
                        display: 'block',
                        margin: '0 auto 10px',
                        maxWidth: imgMax,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={12}>
                    <Box
                      sx={{
                        position: 'relative',
                        p: 3,
                        background: '#eee',
                        minHeight: {xs: 0, lg: 300},
                        borderBottomLeftRadius: {xs: 20, md: 0, lg: 20},
                        borderBottomRightRadius: {xs: 20, md: 20, lg: 20},
                        borderTopRightRadius: {xs: 0, md: 20, lg: 0},
                      }}
                    >
                      <Typography variant="h4">{heading}</Typography>
                      <Typography component="p" variant="p">
                        {heading === 'Modular' && (
                          <>
                            A <strong>Manifest</strong> node combines a custom
                            stack of Web 2.0 and/or Web3 modules
                          </>
                        )}
                        {heading === 'Composable' && (
                          <>
                            Identiically configured <strong>Manifest</strong>{' '}
                            nodes form neighborhoods to provide a service
                          </>
                        )}
                        {heading === 'Federated' && (
                          <>
                            <strong>Manifest</strong> is made up of many
                            neighborhoods that can run for their exact needs
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Container>

      <Modal
        open={helloModalOpen}
        onClose={() => setHelloModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            component="iframe"
            sx={{width: '100%', height: '100%'}}
            src="https://www.youtube.com/embed/LbcHZMfgLy4?autoplay=1&mute=0&controls=1&loop=0&origin=https%3A%2F%2Fwww.liftedinit.org&playsinline=1&enablejsapi=1&widgetid=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Lifted in 90 Seconds"
          />
        </Box>
      </Modal>
    </>
  )
}
