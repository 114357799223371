import React from 'react'
import {Box, Button, Container, Grid, Typography} from '@mui/material'

import Hero from '../components/Hero'

import EcoImage from '../assets/cube-eco.png'
import partnerImageStealth from '../assets/partner-stealth.png'
import partnerImageMusicia from '../assets/partner-musicia.png'
import partnerImageBetteroff from '../assets/partner-betteroff.png'
import partnerImageEndlabs from '../assets/partner-endlabs.png'
import partnerImageRiseAngle from '../assets/partner-riseangle.png'
import partnerImageFreeMap from '../assets/partner-freemap.png'
import partnerImageMangroves from '../assets/partner-mangroves.png'

export default function Ecosystem() {
  return (
    <>
      <Hero>
        <Box
          component="img"
          sx={{
            mt: 4,
            display: 'block',
            mx: 'auto',
            width: {xs: 180, sm: 200, md: 220},
          }}
          alt="Ecosystem Partners"
          src={EcoImage}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />

        <Typography
          variant="h1"
          sx={{
            my: 5,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          Ecosystem Partners
        </Typography>

        <Box
          sx={{
            display: 'block',
            margin: 'auto',
            maxWidth: 'sm',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              my: 2,
              color: '#fff',
              mb: 0,
              fontSize: {xs: '1rem', md: '1.5rem'},
            }}
          >
            <Box component="span" sx={{ml: 1}}>
              These are our early partners many of which are part of The Liftoff
              Program. If you'd like to join, apply now.
            </Box>
          </Typography>
        </Box>

        <Button
          href="https://forms.gle/W5BQs5mQzkQm5wzw5"
          variant="secondary"
          sx={{
            py: 1,
            px: 6,
            mt: 5,
            mx: 'auto',
            display: 'block',
            maxWidth: 180,
          }}
        >
          Apply Now
        </Button>
      </Hero>

      <Container maxWidth="lg">
        <Box component="section" sx={{my: 5}}>
          <Grid container spacing={5} wrap="wrap">
            {[
              {
                name: 'END-Labs',
                category: 'DeFi',
                description: 'Decentralized lending and borrowing framework.',
                href: 'https://end-labs.io/',
                image: partnerImageEndlabs,
              },
              {
                name: 'Stealth Partner',
                category: 'Gaming',
                description: 'Stealth partner in Gaming.',
                href: null,
                image: partnerImageStealth,
              },
              {
                name: 'Stealth Partner',
                category: 'DeFi',
                description: 'Stealth partner in DeFi.',
                href: null,
                image: partnerImageStealth,
              },
              {
                name: 'BettorOff',
                category: 'Sports',
                description: 'Sports betting picks and community platform.',
                href: 'https://www.bettoroff.com/',
                image: partnerImageBetteroff,
              },
              {
                name: 'Musicia',
                category: 'Music',
                description:
                  'Web3 music platform and full-service NFT ecosystem.',
                href: 'https://musicia.io/',
                image: partnerImageMusicia,
              },
              {
                name: 'RiseAngle',
                category: 'Gaming',
                description: 'Building the future of Metaverse gaming.',
                href: 'https://riseangle.com/',
                image: partnerImageRiseAngle,
              },
              {
                name: 'Free Map',
                category: 'Marketplace',
                description: 'Free item marketplace with tokenized-incentives.',
                href: 'https://freeganmap.com/',
                image: partnerImageFreeMap,
              },
              {
                name: 'Mangroves',
                category: 'Data',
                description:
                  'A SQL layer over any blockchain network to simplify development.',
                href: 'https://www.mgdb.io/',
                image: partnerImageMangroves,
              },
            ].map(({name, category, description, href, image}, index) => {
              return (
                <Grid item container xs={12} lg={4} key={`partner-${index}`}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={12}
                    className="pattern-1"
                    sx={{
                      display: 'flex',
                      p: 2,
                      borderTopLeftRadius: {xs: 20},
                      borderTopRightRadius: {xs: 20, md: 0, lg: 20},
                      borderBottomLeftRadius: {xs: 0, md: 20, lg: 0},
                    }}
                  >
                    <Box
                      component="img"
                      src={image}
                      sx={{
                        display: 'block',
                        margin: '0 auto 10px',
                        maxWidth: 180,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={12}>
                    <Box
                      sx={{
                        position: 'relative',
                        p: 3,
                        background: '#eee',
                        minHeight: {xs: 0, md: 230, lg: 300},
                        borderBottomLeftRadius: {xs: 20, md: 0, lg: 20},
                        borderBottomRightRadius: {xs: 20, md: 20, lg: 20},
                        borderTopRightRadius: {xs: 0, md: 20, lg: 0},
                      }}
                    >
                      <Typography variant="h4" sx={{mb: 2}}>
                        {name}
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          background: '#ddd',
                          py: 0.6,
                          px: 5,
                          borderRadius: 1,
                        }}
                      >
                        {category}
                      </Typography>
                      <Typography component="p" variant="p" sx={{mt: 2}}>
                        {description}
                      </Typography>
                      {href ? (
                        <Button
                          variant="primary"
                          href={href}
                          sx={{
                            display: 'block',
                            margin: {xs: 0, sm: 'auto', md: 0, lg: 'auto'},
                            width: {xs: '100%', sm: 180},
                            position: {xs: 'relative', lg: 'absolute'},
                            bottom: {xs: 'inherit', lg: 20},
                            left: {xs: 'inherit', lg: 20},
                            right: {xs: 'inherit', lg: 20},
                            textAlign: 'center',
                          }}
                        >
                          Learn More
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Container>
    </>
  )
}
