import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <h1>Chief Marketing Officer</h1>
      <p>
        Join our team: The Lifted Initiative is at the vanguard of
        revolutionizing Web3 development, crafting the Manifest Network, an
        innovative Layer 0 platform that fuses the robustness of Web2 with the
        transformative potential of Web3 technologies. Our mission is to empower
        developers and businesses to seamlessly create cutting-edge,
        decentralized solutions, ushering in a new paradigm of market
        integration with decentralized technology.
      </p>
      <p>
        Your Role: As Chief Marketing Officer, you will be leading our marketing
        efforts in a time where we are experiencing substantial growth and
        exponential expansion within our like-minded decentralized communities.
        As a key member of our executive team, you will be responsible for
        driving brand awareness, defining our ‘story’, and keep pushing the
        boundaries as a leader within our partners’ ecosystems.
      </p>
      <h2>What you'll do:</h2>
      <ul>
        <li>
          Develop and execute comprehensive marketing strategies aligned with
          our vision
        </li>
        <li>
          Lead the content strategy, creation, and distribution across our
          social media, ensuring high engagement across our socials
        </li>
        <li>
          Build a robust brand identity that resonates with the decentralized
          community and positions us as leaders in the space
        </li>
        <li>
          Coordinate with cross-functional teams (i.e. PR, media, community
          engagement) to drive a cohesive marketing strategy
        </li>
        <li>
          Stay ahead of industry trends, identify opportunities for growth, and
          pivot our marketing efforts accordingly
        </li>
        <li>
          Oversee a high-performing marketing team, providing guidance,
          mentorship, and strategic direction
        </li>
        <li>
          Foster strong relationships with key stakeholders, including
          investors, partners, developers, and users
        </li>
      </ul>
      <h2>What We’re Looking For:</h2>
      <ul>
        <li>
          Proven track record as a successful CMO or senior marketing executive
          in the Web3/AI space
        </li>
        <li>
          5+ years of marketing leadership experience in the blockchain sector
        </li>
        <li>
          Expertise in decentralized technologies, blockchain-based solutions,
          and their applications in marketing
        </li>
        <li>
          A willingness to ‘roll-up-your-sleeves’ while also understanding the
          strategic vision
        </li>
        <li>
          Deep understanding of customer needs, preferences, and behaviors in
          the Web3 ecosystem
        </li>
        <li>
          Strong leadership skills with experience building and managing
          high-performing marketing teams
        </li>
        <li>
          Exceptional communication, presentation, and interpersonal skills with
          an ability to influence cross-functional stakeholders
        </li>
        <li>Bachelor's degree in Marketing or a related field</li>
      </ul>
      <h2>Why Join The Lifted Initiative?</h2>
      <p>
        Joining The Lifted Initiative means becoming part of a pioneering force
        that is setting the benchmark for the future of decentralized
        technology. We're building a sophisticated, accessible, transparent, and
        scalable decentralized infrastructure, merging the practicality of Web2
        with the groundbreaking potential of Web3 through our modular,
        interoperable framework.
      </p>
      <p>
        You'll be part of a team of seasoned professionals from both Web2 and
        Web3 backgrounds, in an environment that fosters professional growth and
        skill enhancement. At The Lifted Initiative, we're not just developing
        products; we're crafting experiences that empower users and redefine the
        digital landscape.
      </p>
    </Container>
  )
}

export default Career
