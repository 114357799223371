import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>Product Manager</h2>
        <p>
          Join our team: Dive into the forefront of innovation with The Lifted
          Initiative, where we simplify the complexities of Web3 development. As
          creators of the Manifest Network, a groundbreaking compute layer 0
          platform, we merge the strengths of Web2 and Web3 technologies. Our
          mission is to empower developers and businesses to effortlessly
          develop custom, forward-thinking solutions that bridge current markets
          with the transformative potential of decentralized technology.
        </p>
        <p>
          Your role: As a Product Manager at The Lifted Initiative, you will
          coordinate the positioning of technology involving AI, decentralized
          computing, and remote computing services. You'll be involved in
          managing the lifecycle of our innovative products, focusing market
          fit, coordination of technical efforts, and agile methodologies.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Collaborate in the planning, development, and launch of features for
            the Manifold Framework and other Lifted projects.
          </li>
          <li>
            Contribute to the Manifest Network's growth, focusing on a
            decentralized and sustainable infrastructure that supports a variety
            of services.
          </li>
          <li>
            Coordinate closely with our UI/UX designers and development team to
            deliver products that are not only technically sound but also
            user-centric.
          </li>
          <li>
            Coordinate and lead business development efforts towards your
            projects.
          </li>
          <li>
            Participate in market research and user testing, turning insights
            into actionable strategies and product improvements.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>
            Eagerness to grow and at least one year of experience in a product
            management role, preferably in technology-focused companies.
          </li>
          <li>
            Ability to grasp complex technical concepts and translate them into
            user-friendly product features.
          </li>
          <li>
            The ability to connect with and influence external stakeholders.
          </li>
          <li>A solid understanding of web3 technologies.</li>
          <li>
            Familiarity with Agile development methodologies and proficiency in
            tools for product management and analytics.
          </li>
          <li>
            Strong communication and collaboration skills, with the ability to
            thrive in a dynamic, fast-paced environment.
          </li>
          <li>
            A problem-solving mindset, coupled with a passion for innovation,
            usability, and customer satisfaction.
          </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          At The Lifted Initiative, you'll join a visionary team dedicated to
          constructing a decentralized infrastructure that's not only advanced
          but also accessible, transparent, and scalable. We blend the best of
          Web2 methodologies with the innovative potential of Web3 to pave the
          way for widespread adoption. Our modular and interoperable framework
          allows even those new to decentralized technology to embark on their
          projects swiftly.
        </p>
        <p>
          Our founders and team are industry veterans from both Web2 and Web3
          backgrounds, poised to nurture and guide your growth in this exciting
          field. With The Lifted Initiative, you're not just joining a company;
          you're becoming part of a movement to redefine the digital landscape.
        </p>
      </div>
    </Container>
  )
}

export default Career
