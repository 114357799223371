import React, {Fragment, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Toolbar,
} from '@mui/material'
import {styled} from '@mui/system'
import {HashLink} from 'react-router-hash-link'
import {useTheme} from '@mui/material/styles'

import MenuIcon from '@mui/icons-material/Menu'

import logo from '../assets/logo.png'

const nav = [
  {
    label: 'Ecosystem',
    route: '/ecosystem',
  },
  {
    label: 'Build',
    children: [
      {
        label: 'Code Repository',
        href: 'https://github.com/liftedinit',
      },
      {
        label: 'The Liftoff Program',
        route: '/liftoff',
      },
      {
        label: 'Web3 Websites',
        route: '/hosting',
      },
      {
        label: "What We're Building",
        anchor: '#build',
      },
    ],
  },
  {
    label: 'About',
    anchor: '#about',
  },
  {
    label: 'Community',
    children: [
      // {
      //   label: 'Discord',
      //   href: 'https://discord.gg/QQXZTXsgqb',
      // },
      {
        label: 'Twitter',
        href: 'https://twitter.com/liftedinit',
      },
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/liftedinit/',
      },
      {
        label: 'Medium',
        href: 'https://medium.com/@TheLiftedInitiative',
      },
    ],
  },
  {
    label: 'Careers',
    route: '/careers',
  },
]

const StyledHashLink = styled(HashLink)({
  width: '100%',
  padding: '10px 0 10px 15px',
  textDecoration: 'none',
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    background: '#f1f1f1',
  },
})

const StyledHashLinkNavItem = styled(HashLink)({
  padding: '7px 15px',
  textDecoration: 'none',
  color: 'rgb(51, 51, 51)',
  borderRadius: '20px',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
})

const NavItem = ({anchor, children, label, href, route}, index) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [id] = useState(`menu-item-${label.toLowerCase().replace(/ /g, '')}`)

  return (
    <Box
      onMouseOver={() => {
        setOpen(true)
      }}
      onMouseOut={() => {
        setOpen(false)
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {anchor ? (
        <StyledHashLinkNavItem
          onClick={() => {
            setOpen(false)
          }}
          to={`/${anchor}`}
        >
          {label}
        </StyledHashLinkNavItem>
      ) : (
        <Button
          id={id}
          variant="nav"
          className={open ? 'open' : null}
          sx={{
            position: 'relative',
            my: 2,
            display: 'block',
            '&.open': {background: 'rgba(0, 0, 0, 0.04)'},
          }}
          href={href}
          onClick={() => {
            if (children) return
            setOpen(false)
            if (route) navigate(route)
          }}
        >
          {label}
        </Button>
      )}

      {children ? (
        <Box
          sx={{
            visibility: open ? 'visible' : 'hidden',
            opacity: open ? 1 : 0,
            transition: 'opacity 300ms',
            position: 'absolute',
            top: 58,
            zIndex: 5,
            minWidth: 200,
            background: '#fff',
            boxShadow: '0 5px 10px rgb(0 0 0 / 10%)',
            borderRadius: 2,
          }}
        >
          {children.map((child, childIndex) => {
            return (
              <MenuItem
                key={`menu-item-${index}-${childIndex}`}
                sx={{
                  '&:first-of-type': {
                    borderTopRightRadius: 6,
                    borderTopLeftRadius: 6,
                  },
                  p: 0,
                }}
              >
                {child.anchor ? (
                  <StyledHashLink
                    onClick={() => {
                      setOpen(false)
                    }}
                    to={`/${child.anchor}`}
                    sx={{
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <ListItemText primary={child.label} />
                  </StyledHashLink>
                ) : (
                  <ListItemButton
                    href={child.href}
                    onClick={() => {
                      setOpen(false)
                      if (child.route) navigate(child.route)
                    }}
                    sx={{
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    {child.label}
                  </ListItemButton>
                )}
              </MenuItem>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default function Header() {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const theme = useTheme()
  console.log('==theme', theme)

  return (
    <>
      <div id="back-to-top-anchor" />
      <Box
        sx={{position: 'sticky', top: 0, zIndex: 4}}
        className="page page--landing"
      >
        <AppBar
          position="static"
          sx={{background: '#fff', boxShadow: 'none', color: '#333'}}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                component="img"
                sx={{
                  display: {xs: 'none', md: 'flex'},
                  height: 40,
                  mr: 4,
                  cursor: 'pointer',
                }}
                alt="The Lifted Initiative"
                src={logo}
                onClick={() => {
                  navigate('/')
                  window.scrollTo(0, 0)
                }}
              />

              <Box
                sx={{
                  flex: '30%',
                  justifyContent: 'flex-start',
                  display: {xs: 'flex', md: 'none'},
                }}
              >
                <IconButton
                  size="large"
                  onClick={() => setDrawerOpen(true)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: {xs: 'flex', md: 'none'},
                  flex: '30%',
                  justifyContent: 'center',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    cursor: 'pointer',
                  }}
                  alt="The Lifted Initiative"
                  src={logo}
                  onClick={() => {
                    navigate('/')
                    window.scrollTo(0, 0)
                  }}
                />
              </Box>

              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                {nav.map((item, index) => {
                  return <NavItem key={`menu-item-${index}`} {...item} />
                })}
              </Box>

              <Box
                sx={{display: 'flex', flex: '30%', justifyContent: 'flex-end'}}
              >
                <Button
                  onClick={() => navigate('/liftoff')}
                  variant="secondary"
                  sx={{
                    my: 2,
                    display: {xs: 'none', sm: 'block'},
                    whiteSpace: 'nowrap',
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List sx={{width: 250}}>
          {nav.map(({anchor, children, label, href, route}, index) => {
            return (
              <Fragment key={`mobile-menu-item-${index}`}>
                <ListItem disablePadding sx={{borderBottom: '1px solid #ddd'}}>
                  {anchor ? (
                    <StyledHashLink
                      onClick={() => {
                        setDrawerOpen(false)
                      }}
                      to={`/${anchor}`}
                    >
                      <ListItemText primary={label} />
                    </StyledHashLink>
                  ) : (
                    <ListItemButton
                      href={href}
                      onClick={() => {
                        if (children) return
                        setDrawerOpen(false)
                        if (route) navigate(route)
                      }}
                      sx={{
                        '&:hover': {
                          background: children ? '#fff' : '#f1f1f1',
                          cursor: children ? 'default' : 'pointer',
                        },
                      }}
                    >
                      <ListItemText primary={label} />
                    </ListItemButton>
                  )}
                </ListItem>
                {children ? (
                  <Box sx={{background: '#fff'}}>
                    {children.map((child, childIndex) => {
                      return (
                        <ListItem
                          disablePadding
                          sx={{borderBottom: '1px solid #ddd', pl: 2}}
                          key={`mobile-menu-item-${index}-${childIndex}`}
                        >
                          {child.anchor ? (
                            <StyledHashLink
                              onClick={() => {
                                setDrawerOpen(false)
                              }}
                              to={`/${child.anchor}`}
                            >
                              <ListItemText primary={child.label} />
                            </StyledHashLink>
                          ) : (
                            <ListItemButton
                              href={child.href}
                              onClick={() => {
                                setDrawerOpen(false)
                                if (child.route) navigate(child.route)
                              }}
                            >
                              <ListItemText primary={child.label} />
                            </ListItemButton>
                          )}
                        </ListItem>
                      )
                    })}
                  </Box>
                ) : null}
              </Fragment>
            )
          })}
          <ListItem
            disablePadding
            sx={{borderBottom: '1px solid #ddd'}}
            key={`mobile-menu-item-last`}
          >
            <ListItemButton
              href=""
              onClick={() => {
                setDrawerOpen(false)
                navigate('/liftoff')
              }}
            >
              <ListItemText primary="Join Liftoff Program" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}
