import React from 'react'
import {Box, Button, Container, Grid, Typography} from '@mui/material'

import Hero from '../components/Hero'

import ManifestLogo from '../assets/manifest-logo.png'

export default function Hosting() {
  return (
    <>
      <Hero color={0x2a363d}>
        <Box
          component="img"
          sx={{
            mt: 4,
            display: 'block',
            mx: 'auto',
          }}
          alt="The Manifest Network"
          src={ManifestLogo}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />

        <Typography
          variant="h1"
          sx={{
            my: 5,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          Decentralized web hosting on The Manifest Network
        </Typography>

        <Button
          href="https://forms.gle/7aSL2eoUKtxvVxf56"
          variant="secondary"
          sx={{
            py: 1,
            px: 6,
            mt: 5,
            mx: 'auto',
            display: 'block',
            background: '#5cadd6',
            '&:hover': {
              background: '#5cadd6',
            },
            maxWidth: 250,
          }}
        >
          Become a Partner
        </Button>
      </Hero>

      <Container
        disableGutters
        maxWidth={false}
        sx={{
          background: '#c2e0f0',
          mb: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box component="section" sx={{py: 5, px: 3}} maxWidth="sm">
          <Grid item xs={12}>
            <Typography variant="h3" sx={{color: '#2a363d'}}>
              Our technology allows fully decentralized web hosting and services
              to power today's Web2 domains.
            </Typography>
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box component="section">
          <Grid container spacing={1} wrap="wrap">
            <Grid item xs={12}>
              <Typography variant="h4">
                Why Decentralized Web Hosting on the Manifest Network?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Technical Resilience</Typography>
              <Typography component="p" variant="p">
                Increase the technical resilience of your website, the outage of
                one component will not bring your website down.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Security & Privacy</Typography>
              <Typography component="p" variant="p">
                Enhance security and privacy. Website information is not
                accessible by third parties who would otherwise be involved with
                hosting a centralized website.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Censorship Resistance</Typography>
              <Typography component="p" variant="p">
                Protect your business against de-platforming and censorship,
                Your website cannot easily be intentionally blocked or
                decommissioned by a central authority.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Search Engine Performance</Typography>
              <Typography component="p" variant="p">
                Unlike other Web3 web hosting services, your company will still
                appear on top search engines like Google. With Lifted, you have
                the ability to still publish your website in a Web2 manner.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Entry Point to Web3</Typography>
              <Typography component="p" variant="p">
                Provide your company an entry point to Web3 with associated
                marketing benefits.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
