import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>System Engineer</h2>
        <p>
          Join our team: The Lifted Initiative is at the vanguard of
          revolutionizing Web3 development, crafting the Manifest Network, an
          innovative Layer 0 platform that fuses the robustness of Web2 with the
          transformative potential of Web3 technologies. Our mission is to
          empower developers and businesses to seamlessly create cutting-edge,
          decentralized solutions, ushering in a new paradigm of market
          integration with decentralized technology.
        </p>
        <p>Your role</p>
        <p>
          As a System Engineer at The Lifted Initiative, you will be integral to
          developing and maintaining the infrastructure that supports our
          decentralized applications. This role requires a blend of expertise in
          systems engineering and a deep understanding of both Web2 and Web3
          technologies. You will collaborate with a dynamic team of engineers
          and industry experts to ensure our systems are scalable, secure, and
          efficient.
        </p>
        <p>What you'll do</p>
        <ul>
          <li>
            Design, build, and maintain the infrastructure necessary for optimal
            operation of decentralized applications.
          </li>
          <li>
            Implement and manage server networks, databases, and software
            environments.
          </li>
          <li>
            Ensure system connectivity of network and server infrastructure.
          </li>
          <li>
            Apply best practices for continuous integration and deployment in an
            infrastructure-as-code environment.
          </li>
          <li>
            Develop and enhance tools for monitoring, logging, and managing
            infrastructure security.
          </li>
          <li>Proactively identify and troubleshoot system issues.</li>
          <li>
            Collaborate with development teams to integrate new technologies and
            systems into existing infrastructure.
          </li>
          <li>Document system configurations, processes, and procedures.</li>
          <li>
            Engage in ongoing learning and technology review to bring innovative
            solutions to our infrastructure challenges.
          </li>
        </ul>
        <p>What We’re Looking For</p>
        <ul>
          <li>5+ years of experience in the field</li>
          <li>
            Proven systems engineering experience with a strong Linux/Unix
            Administration background.
          </li>
          <li>
            Skilled in cloud technologies and services (AWS, GCP, DigitalOcean).
          </li>
          <li>
            Experience with containerization and orchestration technologies such
            as Docker and Kubernetes.
          </li>
          <li>
            Proficiency in using automation and configuration tools like
            Terraform, Ansible, or Salt.
          </li>
          <li>
            Solid scripting skills in at least one of the following: Python,
            Bash, Java, or similar languages.
          </li>
          <li>
            Knowledge of continuous integration and delivery (CI/CD) practices
            and tools (Jenkins, GitLab CI, ArgoCD).
          </li>
          <li>
            Understanding of network services, routing protocols, and
            configuration.
          </li>
          <li>
            Familiarity with load balancing and reverse proxy solutions (NGINX,
            HAProxy).
          </li>
          <li>
            Experience with monitoring and alerting tools (Prometheus, Grafana).
          </li>
          <li>
            Excellent problem-solving skills and ability to document solutions
            and systems.
          </li>
          <li>
            Cryptocurrency and blockchain technology experience is a plus.
          </li>
        </ul>
        <p>Why Join The Lifted Initiative?</p>
        <p>
          Joining The Lifted Initiative means becoming part of a pioneering
          force that is setting the benchmark for the future of decentralized
          technology. We're building a sophisticated, accessible, transparent,
          and scalable decentralized infrastructure, merging the practicality of
          Web2 with the groundbreaking potential of Web3 through our modular,
          interoperable framework.
        </p>
        <p>
          You'll be part of a team of seasoned professionals from both Web2 and
          Web3 backgrounds, in an environment that fosters professional growth
          and skill enhancement. At The Lifted Initiative, we're not just
          developing products; we're crafting experiences that empower users and
          redefine the digital landscape.
        </p>
      </div>
    </Container>
  )
}

export default Career
