import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>Mid-Level Backend Developer (Cosmos/Rust)</h2>
        <p>
          Join our team: At The Lifted Initiative, we're at the forefront of
          redefining Web3 development. We've innovated the Manifest Network, a
          ground-breaking Layer 0 solution, merging the robustness of Web2 with
          the transformative capabilities of Web3 technologies. Our goal is to
          enable developers and businesses to seamlessly forge state-of-the-art,
          decentralized solutions, fostering a new era of market integration
          with decentralized technology.
        </p>
        <p>
          Your role: As a Mid-Level Backend Developer with a focus on Cosmos and
          Go, you will be instrumental in architecting and coding the core
          components of our decentralized infrastructure. Your expertise in
          blockchain protocol development, smart contract craftsmanship, and
          performance optimization will be crucial in elevating the Manifest
          Network, ensuring high-performance, secure, and scalable blockchain
          solutions.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Design, develop, and implement smart contracts and blockchain
            protocols within the Cosmos SDK, leveraging your expertise in Go for
            optimal performance and security.
          </li>
          <li>
            Play a critical role in the architectural design and integration of
            blockchain solutions into the Manifold Framework and other Lifted
            products.
          </li>
          <li>
            Contribute to the continual refinement of the Manifest Network,
            focusing on robust decentralized architecture and consensus
            algorithms to support diverse services at scale.
          </li>
          <li>
            Uphold best practices in code quality, security audits, and
            performance benchmarking, driving forward the reliability and
            efficiency of our blockchain applications.
          </li>
          <li>
            Maintain active involvement in the blockchain community, staying
            informed on the latest protocols, security advancements, and
            development frameworks, to inform and inspire innovative solutions
            within our platform.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>
            Proven track record in blockchain development, demonstrated through
            existing projects or contributions in the Cosmos ecosystem, and
            fluency in Rust programming.
          </li>
          <li>
            Proven experience in developing infrastructure with distributed
            technologies.
          </li>
          <li>At least two years of blockchain development experience.</li>
          <li>
            Deep technical understanding of blockchain principles, consensus
            mechanisms, cryptographic algorithms, and smart contract security.
          </li>
          <li>
            Proficiency in designing and deploying high-stakes, scalable, and
            secure decentralized applications, with a strong focus on code
            optimization and system architecture.
          </li>
          <li>
            Familiarity with P2P networking, blockchain node architecture, and
            transaction lifecycle management.
          </li>
          <li>
            Exceptional problem-solving skills, with an analytical mindset
            geared towards innovation and cutting-edge technology development.
          </li>
          <li>
            Robust communication skills, capable of articulating complex
            technical concepts and collaborating effectively within a
            multidisciplinary team.
          </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          By joining The Lifted Initiative, you're stepping into a team of
          pioneers committed to crafting a decentralized infrastructure that's
          sophisticated, accessible, transparent, and scalable. We bridge the
          practicality of Web2 with the innovation of Web3, driving rapid,
          seamless deployment of decentralized projects through our modular,
          interoperable framework.
        </p>
        <p>
          You'll be collaborating with a team of seasoned professionals from
          both the Web2 and Web3 realms, offering a rich environment for
          professional growth and skill enhancement. At The Lifted Initiative,
          we're not just building solutions; we're setting the standard for the
          future of decentralized technology.
        </p>
      </div>
    </Container>
  )
}

export default Career
