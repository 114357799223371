import React, {useEffect, useState} from 'react'

import {Box, Typography} from '@mui/material'

import Hero from '../components/Hero'
import ManifestNetwork from '../components/ManifestNetwork'
import WhatWereBuilding from '../components/WhatWereBuilding'
import MeetAlberto from '../components/MeetAlberto'
import AboutUs from '../components/AboutUs'

import imgCube1 from '../assets/hero-cube-1.png'
import imgCube2 from '../assets/hero-cube-2.png'
import imgCube3 from '../assets/hero-cube-3.png'
import imgCube4 from '../assets/hero-cube-4.png'
import imgCube5 from '../assets/hero-cube-5.png'
import imgLogo2 from '../assets/logo2.png'
import imgLogo3 from '../assets/logo3.png'

const randomNumber = (min, max) => Math.random() * (max - min) + min

const cubes = [
  {
    top: 60,
    left: '10%',
    width: 35,
    src: imgCube1,
  },
  {
    top: 0,
    left: '25%',
    width: 50,
    src: imgCube2,
  },
  {
    top: 60,
    left: '40%',
    width: 50,
    src: imgCube3,
  },
  {
    top: 0,
    left: '60%',
    width: 50,
    src: imgCube4,
  },
  {
    top: 60,
    left: '75%',
    width: 60,
    src: imgCube5,
  },
]

export default function Landing() {
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (init) return

    const cubeElements = document.querySelectorAll('.cube')
    const arr = ['x', 'y']

    cubeElements.forEach((el) => {
      setTimeout(() => {
        el.classList.add(`float-${arr[Math.floor(Math.random() * arr.length)]}`)
      }, randomNumber(1000, 3000))
    })

    setInit(true)
  }, [init])

  return (
    <>
      <Hero>
        <Box
          component="img"
          sx={{mt: 4, display: 'block', mx: 'auto'}}
          alt="The Lifted Initiative"
          src={imgLogo2}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />
        <Box
          component="img"
          sx={{display: 'block', mx: 'auto', mt: 3, mb: 6, maxWidth: 300}}
          alt="The Lifted Initiative"
          src={imgLogo3}
          className="fade_in"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />
        <Typography
          variant="h1"
          sx={{
            mb: 0,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          Developing in Web3 is hard
          <br /> We're here to simplify it
        </Typography>

        <Box
          sx={{
            position: 'relative',
            width: {xs: '100%', sm: 640},
            height: 150,
            mx: 'auto',
            mt: 5,
          }}
        >
          {cubes.map((cube, index) => {
            return (
              <Box
                key={index}
                component="img"
                className="cube fade_in"
                src={cube.src}
                sx={{
                  zIndex: 2,
                  position: 'absolute',
                  top: cube.top,
                  left: cube.left,
                  width: cube.width,
                }}
                onLoad={(ev) => {
                  ev.target.classList.add('fade_in--loaded')
                }}
              />
            )
          })}
        </Box>
      </Hero>

      <ManifestNetwork />

      <WhatWereBuilding />

      <MeetAlberto />

      <AboutUs />
    </>
  )
}
