import {Box, Button, Container, Grid, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'

import imgManifestNetwork from '../assets/manifest-network.png'
import imgManifoldNetwork from '../assets/manifold-network.png'
import imgManyProtocol from '../assets/many-protocol.png'
import imgCube0 from '../assets/cube-1.png'

export default function WhatWereBuilding() {
  const navigate = useNavigate()

  return (
    <Container>
      <Box id="build" component="section" sx={{pt: 10, px: 3}}>
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <Typography variant="h3">What We're Building</Typography>
            <Typography component="p" variant="p">
              We've reimagined the entire tech stack to provide a user-friendly
              experience. From the framework all the way down to the hardware
              architecture, our core technology provides a simple yet powerful
              UX. Oh, and we're also open-source.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box component="section" sx={{px: 3}}>
        <Grid container spacing={2} wrap="wrap">
          {[
            {
              heading: 'The Manifold Framework',
              paragraph:
                'A suite of drag-and-drop building block modules that allow you to combine Web2 + Web3 functionality for custom solutions.',
              image: imgManifoldNetwork,
              cta: 'Learn More',
              href: 'https://github.com/liftedinit/many-framework',
            },
            {
              heading: 'The Manifest Network',
              paragraph:
                'A decentralized and planet-friendly hardware layer that powers services and allows both deterministic and non-deterministic processing.',
              image: imgManifestNetwork,
              cta: 'Learn More',
              route: '/liftoff',
            },
            {
              heading: 'The Many Protocol',
              paragraph:
                'A communications protocol to exchange messages, data, and assets across sovereign blockchains.',
              image: imgManyProtocol,
              cta: 'Start Building',
              href: 'https://github.com/many-protocol',
            },
          ].map(({cta, heading, paragraph, route, href, image}, index) => {
            return (
              <Grid
                item
                container
                xs={12}
                lg={4}
                key={`build-product-${index}`}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={12}
                  className="pattern-1"
                  sx={{
                    p: 2,
                    borderTopLeftRadius: {xs: 20},
                    borderTopRightRadius: {xs: 20, md: 0, lg: 20},
                    borderBottomLeftRadius: {xs: 0, md: 20, lg: 0},
                  }}
                >
                  <Box
                    component="img"
                    src={image}
                    sx={{
                      display: 'block',
                      margin: '0 auto 10px',
                      maxWidth: 180,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={12}>
                  <Box
                    sx={{
                      position: 'relative',
                      p: 3,
                      background: '#eee',
                      minHeight: {xs: 0, lg: 400},
                      borderBottomLeftRadius: {xs: 20, md: 0, lg: 20},
                      borderBottomRightRadius: {xs: 20, md: 20, lg: 20},
                      borderTopRightRadius: {xs: 0, md: 20, lg: 0},
                    }}
                  >
                    <Typography variant="h4">{heading}</Typography>
                    <Typography component="p" variant="p">
                      {paragraph}
                    </Typography>
                    <Button
                      href={href}
                      variant="primary"
                      sx={{
                        display: 'block',
                        margin: {xs: 0, sm: 'auto', md: 0, lg: 'auto'},
                        width: {xs: '100%', sm: 180, md: 180},
                        position: {xs: 'relative', lg: 'absolute'},
                        bottom: {xs: 'inherit', lg: 20},
                        left: {xs: 'inherit', lg: 20},
                        right: {xs: 'inherit', lg: 20},
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        if (route) navigate(route)
                      }}
                    >
                      {cta}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Box>

      <Box component="section" sx={{py: 10, px: 3}}>
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <Typography variant="h3">Modules</Typography>
            <Typography component="p" variant="p">
              Our modules are wrapped with an API to be composable and
              interoperable with each other. We're adding more services to the
              Manifold Framework continuously.
            </Typography>
          </Grid>
          {[
            {
              icon: imgCube0,
              name: 'Blocks',
              description: 'Make Data immutable and auditable',
            },
            {
              icon: imgCube0,
              name: 'Ledger',
              description: 'Mint, burn, and transact custom tokens',
            },
            {
              icon: imgCube0,
              name: 'Accounts',
              description: 'Send transactions, check balances',
            },
            {
              icon: imgCube0,
              name: 'MultiSig',
              description: 'Require multiple approvals for transactions',
            },
            {
              icon: imgCube0,
              name: 'Web',
              description: 'Translate HTTP requests',
            },
            {
              icon: imgCube0,
              name: 'Data',
              description: 'Map keys to arbitrary values',
            },
            {
              icon: imgCube0,
              name: 'Names',
              description: 'Map human-readable text to addresses',
            },
            {
              icon: imgCube0,
              name: 'Files',
              description: 'Store, retrieve, and delete data as files',
            },
          ].map(({icon, name, description}, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={`modules-${index}`}>
                <Box
                  sx={{
                    p: 3,
                    height: '100%',
                    background: '#eee',
                    borderRadius: 4,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={3} md={12}>
                      <Box
                        component="img"
                        src={icon}
                        sx={{
                          width: 40,
                          mb: 1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} md={12}>
                      <Typography variant="h5" sx={{m: 0}}>
                        {name}
                      </Typography>
                      <Typography
                        component="p"
                        variant="p"
                        sx={{fontSize: '1rem'}}
                      >
                        {description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Container>
  )
}
