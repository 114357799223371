import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>Site Reliability Engineer</h2>
        <p>
          Join our team: The Lifted Initiative is at the vanguard of
          revolutionizing Web3 development, crafting the Manifest Network, an
          innovative Layer 0 platform that fuses the robustness of Web2 with the
          transformative potential of Web3 technologies. Our mission is to
          empower developers and businesses to seamlessly create cutting-edge,
          decentralized solutions, ushering in a new paradigm of market
          integration with decentralized technology.
        </p>
        <p>
          Your role: As a Site Reliability Engineer at The Lifted Initiative,
          your role is pivotal in improving the underlying infrastructure of our
          decentralized applications. You will use your dev ops experience to
          help build, monitor and improve our infrastructure platforms, which
          include and not limited to servers, networks and databases. You will
          be involved with cutting edge technology and collaborating internally
          and externally with industry pioneers.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Deploy cryptocurrency node networks and underlying architecture to
            support decentralized systems.
          </li>
          <li>
            Develop automation pipelines and continuously improve and deploy
            systems using industry best practices including
            infrastructure-as-code and configuration management.
          </li>
          <li>
            Manage the security of systems such as firewalls and security
            groups, and approach problems with a security-first mindset.
          </li>
          <li>
            Ongoing monitoring of our infrastructure platform, including
            servers, networks, applications, databases and other platform
            technologies.
          </li>
          <li>
            Maintain and improve monitoring, alerting and security of existing
            infrastructure.
          </li>
          <li>Build advanced monitoring and alerting tools.</li>
          <li>
            Compile and manage infrastructure and security documentation
            including systems inventory, alert procedures, and process run
            books.
          </li>
          <li>
            Troubleshoot and assist in diagnosing, solving or escalating
            infrastructure problems.
          </li>
          <li>
            Monitor databases, queues, container orchestration systems and other
            fundamental technologies, and participate in devising a strategy of
            platform reliability.
          </li>
          <li>
            Strong sense of ownership, entrepreneurial spirit, capable of
            driving towards solutions independently while seeking feedback when
            appropriate.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>5+ years of Linux systems administration experience</li>
          <li>Previous SRE experience and knowledge of best practices</li>
          <li>
            Experience deploying and administering monitoring and alerting
            technologies such as Prometheus, Grafana, Thanos
          </li>
          <li>
            Experience with CI/CD systems such as Jenkins, Gitlab, ArgoCD, etc.
          </li>
          <li>
            Familiarity with cloud providers such as AWS, GCP, DigitalOcean
          </li>
          <li>Terraform experience</li>
          <li>Docker experience</li>
          <li>Kubernetes experience</li>
          <li>Solid command of Ansible, Salt or similar technologies</li>
          <li>
            Ability to program (structured and OOP) using one or more high-level
            languages, such as Python, Java, C/C++, Rust, and JavaScript
          </li>
          <li>
            Experience with distributed storage technologies such as NFS and
            Amazon S3
          </li>
          <li>Capability to write concise technical documentation</li>
          <li>
            Understanding of reverse proxying and load balancing such as NGINX,
            HAProxy, etc.
          </li>
          <li>Experience with Git</li>
          <li>Cryptocurrency experience</li>
          <li>
            Experience with PostgreSQL; understanding of replication, failover,
            backups
          </li>
          <li>
            Experience operating cryptocurrency nodes, bonus points for
            Tendermint
          </li>
          <li>
            Experience with Minio Object Storage or S3-compatible storage
            systems
          </li>
          <li>Experience with CI pipelines (Gitlab CI, Jenkins, etc.)</li>
          <li>Experience developing REST APIs or utilizing database ORMs</li>
          <li>Full-stack development experience </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          Joining The Lifted Initiative means becoming part of a pioneering
          force that is setting the benchmark for the future of decentralized
          technology. We're building a sophisticated, accessible, transparent,
          and scalable decentralized infrastructure, merging the practicality of
          Web2 with the groundbreaking potential of Web3 through our modular,
          interoperable framework.
        </p>
        <p>
          You'll be part of a team of seasoned professionals from both Web2 and
          Web3 backgrounds, in an environment that fosters professional growth
          and skill enhancement. At The Lifted Initiative, we're not just
          developing products; we're crafting experiences that empower users and
          redefine the digital landscape.
        </p>
      </div>
    </Container>
  )
}

export default Career
