import {Box, Container, Grid, Typography} from '@mui/material'

export default function AboutUs() {
  return (
    <Container>
      <Box
        id="about"
        component="section"
        sx={{
          px: {xs: 3, sm: 10},
          py: {xs: 10, sm: 10},
        }}
      >
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <Typography variant="h3">About Us</Typography>
            <Typography component="p" variant="p">
              The Lifted Initiative is building decentralized infrastructure
              that meets the demands of our time; to be easy, accessible,
              transparent, and scalable. We’re integrating lessons and
              methodologies crafted in Web2 technology with decentralized Web3
              to unlock mass adoption. With a modular, interoperable framework,
              developers (of all backgrounds) can quickly start their
              decentralized project in minutes.
            </Typography>
            <Typography component="p" variant="p">
              The founding team is comprised of veterans in both Web2 and Web3
              industries, with experience in building and launching
              organizations (Google, Slack, Facebook, Samsung, Baseline,
              ConsenSys). We have what it takes to grow an organization to
              launch a product that developers truly want. Here at Lifted, the
              user comes first.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
