import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>UI/UX Designer</h2>
        <p>
          Join our team: At The Lifted Initiative, we're on a mission to
          simplify the complexities of Web3 development. We are the creators of
          Manifest, a pioneering Layer 0 network that seamlessly blends the
          power of Web2 and Web3 technologies. Our platform empowers businesses
          and developers to craft custom solutions effortlessly, bridging
          today's markets with the revolutionary potential of decentralized
          tech.
        </p>
        <p>
          Your role: As a UI/UX Designer at The Lifted Initiative, you will be
          instrumental in redefining the tech landscape. Our design philosophy
          involves using web3 technologies with intuitive interfaces that often
          hide the complexities of our cutting-edge distributed technologies.
          Your designs will provide the gateway between our technologies and our
          diverse target user base.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Design intuitive and compelling UI/UX for the Manifold Framework and
            other Lifted projects, making complex Web2 and Web3 technologies
            accessible and straightforward.
          </li>
          <li>
            Contribute to the development of the Manifest Network, focusing on a
            decentralized and sustainable hardware layer that powers various
            services.
          </li>
          <li>
            Work closely with our development team to ensure seamless
            integration and functionality of our API-wrapped modules.
          </li>
          <li>
            Engage in user research and testing, translating insights into
            actionable design improvements.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>At least two years in UI design for technology products.</li>
          <li>
            Proven experience in UI/UX design, with a portfolio showcasing your
            work in web and/or mobile applications.
          </li>
          <li>Familiarity with Web3 concepts.</li>
          <li>
            Strong understanding of design principles and user-centered design
            methodologies.
          </li>
          <li>
            Proficiency in design and prototyping tools (e.g., Sketch, Figma,
            Adobe Creative Suite).
          </li>
          <li>
            Excellent communication skills and the ability to work
            collaboratively in a dynamic, fast-paced environment.
          </li>
          <li>
            A mindset geared towards innovation, usability, and customer
            satisfaction.
          </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          The Lifted Initiative is building a decentralized infrastructure that
          meets the demands of our time; to be easy, accessible, transparent,
          and scalable. We’re integrating lessons and methodologies crafted in
          Web2 technology with decentralized Web3 to unlock mass adoption. With
          a modular, interoperable framework, developers (of all backgrounds)
          can quickly start their decentralized project in minutes.
        </p>
        <p>
          The founding team is comprised of veterans in both Web2 and Web3
          industries, with experience in building and launching organizations.
          We have what it takes to grow an organization to launch a product that
          developers truly want.
        </p>
      </div>
    </Container>
  )
}

export default Career
