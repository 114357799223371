import React, {useState} from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Modal,
  Typography,
} from '@mui/material'

import imgAlberto from '../assets/alberto.webp'

import {modalStyle} from '../theme'

export default function MeetAlberto() {
  const [albertoModalOpen, setAlbertoModalOpen] = useState(false)

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            px: {xs: 3, sm: 10},
            py: {xs: 10, sm: 10},
            background: '#eee',
          }}
        >
          <Container>
            <Grid container spacing={5} wrap="wrap">
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={imgAlberto}
                  sx={{
                    maxWidth: '80%',
                    boxShadow: '2px 2px 22px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Grid>
              <Grid item container xs={12} md={6}>
                <Typography variant="h3">Meet Alberto</Typography>
                <Typography component="p" variant="p">
                  We used the Manifold Framework to build an easy to use
                  multi-sig wallet:
                </Typography>
                <List sx={{listStyleType: 'disc', ml: 2}}>
                  <ListItem sx={{display: 'list-item'}}>
                    Fully operated on a decentralized network
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    Compatible with multiple hardware wallets
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    Multiple tokens can be managed
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    Social, email, seed phrase, and hardware authenticator login
                    options
                  </ListItem>
                </List>

                <Grid item container xs={12} sx={{mt: 4}}>
                  <Grid item xs={12} sm={6} sx={{mb: {xs: 2, sm: 0}}}>
                    <Button
                      href="https://alberto.app/"
                      target="_blank"
                      variant="primary"
                      sx={{width: '90%'}}
                    >
                      Launch App
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="secondary"
                      sx={{width: '90%'}}
                      onClick={() => setAlbertoModalOpen(true)}
                    >
                      Learn More
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>

      <Modal
        open={albertoModalOpen}
        onClose={() => setAlbertoModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            component="iframe"
            sx={{width: '100%', height: '100%'}}
            src="https://www.youtube.com/embed/O6agcEked4I?autoplay=1&mute=0&controls=1&loop=0&origin=https%3A%2F%2Fwww.liftedinit.org&playsinline=1&enablejsapi=1&widgetid=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Lifted in 90 Seconds"
          />
        </Box>
      </Modal>
    </>
  )
}
