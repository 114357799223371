import React, {useEffect, useRef, useState} from 'react'
import {isMobile} from 'is-mobile'
import {Box, Container} from '@mui/material'
import {useLocation} from 'react-router-dom'

export default function Hero({children, color}) {
  const [vantaEffect, setVantaEffect] = useState(null)
  const vantaRef = useRef(null)
  let location = useLocation()

  useEffect(() => {
    if (!isMobile()) {
      if (!vantaEffect) {
        setVantaEffect(
          window.VANTA.WAVES({
            el: vantaRef.current,
            mouseControls: false,
            touchControls: false,
            gyroControls: false,
            minHeight: 500.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: color || 0x4d323b,
            shininess: 0.0,
            waveHeight: 11.0,
            waveSpeed: 0.3,
            zoom: 1.5,
          })
        )
      }
      return () => {
        if (vantaEffect) vantaEffect.destroy()
      }
    }
  }, [vantaEffect]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        component="section"
        sx={{
          height: 640,
          position: 'relative',
        }}
      >
        <Box
          className="animate__animated animate__fadeIn"
          sx={{
            position: 'relative',
            color: '#fff',
            zIndex: 3,
            margin: 'auto',
            textAlign: 'center',
            px: {xs: 2, sm: 0},
            pt: 5,
          }}
        >
          {children}
        </Box>

        <Box
          className={location.pathname === '/hosting' ? 'bg-2' : 'bg-1'}
          ref={vantaRef}
          sx={{
            bottom: 0,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
          }}
        />
      </Box>
    </Container>
  )
}
