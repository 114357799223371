import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>DevOps Engineer</h2>
        <p>
          Join our team: The Lifted Initiative is at the vanguard of
          revolutionizing Web3 development, crafting the Manifest Network, an
          innovative Layer 0 platform that fuses the robustness of Web2 with the
          transformative potential of Web3 technologies. Our mission is to
          empower developers and businesses to seamlessly create cutting-edge,
          decentralized solutions, ushering in a new paradigm of market
          integration with decentralized technology.
        </p>
        <h2>Your role:</h2>
        <p>
          As a DevOps Engineer at The Lifted Initiative, you will play a crucial
          role in the operational success of our decentralized applications.
          This position demands a skilled engineer with a comprehensive
          background in software development and operations, capable of
          managing, scaling, and optimizing our continuous integration and
          deployment pipelines.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Develop, scale, and maintain automated CI/CD pipelines for
            decentralized applications.
          </li>
          <li>
            Collaborate with software engineers to enhance system development
            life cycle processes.
          </li>
          <li>
            Implement monitoring, logging, and alert systems to ensure proactive
            responses to infrastructure and application issues.
          </li>
          <li>
            Manage infrastructure provisioning and deployment using
            infrastructure-as-code methodologies.
          </li>
          <li>
            Maintain and improve existing infrastructure to ensure uptime and
            efficient operation of live systems.
          </li>
          <li>
            Automate processes to enhance system efficiencies and reduce human
            intervention.
          </li>
          <li>
            Ensure system security through best practices and regular review of
            security protocols.
          </li>
          <li>
            Lead incident response and root-cause analysis to mitigate and
            prevent recurring issues.
          </li>
          <li>
            Engage with new technologies and approaches to remain at the
            forefront of technological advancements in DevOps practices.
          </li>
          <li>
            Document system development processes, procedures, and
            configurations.
          </li>
        </ul>
        <p>What We’re Looking For:</p>
        <ul>
          <li>Extensive experience with Linux/Unix system administration.</li>
          <li>
            Proficiency in scripting languages such as Python, Bash, or Ruby.
          </li>
          <li>
            Strong background in cloud services (AWS, GCP, Azure) including
            automation and orchestration.
          </li>
          <li>
            Experience with Docker, Kubernetes, and other container management
            solutions.
          </li>
          <li>
            Solid understanding of CI/CD tools such as Jenkins, GitLab CI, or
            ArgoCD.
          </li>
          <li>
            Expertise in infrastructure as code (IaC) using Terraform, Ansible,
            or similar tools.
          </li>
          <li>Knowledge of network stacks, protocols, and architectures.</li>
          <li>
            Familiarity with security practices and tools in cloud and network
            environments.
          </li>
          <li>
            Experience with monitoring tools like Prometheus, Grafana, and ELK
            stack.
          </li>
          <li>
            Strong analytical and troubleshooting skills, with a proactive
            mindset toward problem-solving.
          </li>
          <li>
            Experience in managing large-scale, secure, and efficient IT
            environments.
          </li>
          <li>
            Ability to work collaboratively in a fast-paced, team environment.
          </li>
          <li>
            Prior exposure to blockchain technology or cryptocurrency platforms
            is a plus.
          </li>
        </ul>
        <p>Why Join The Lifted Initiative?</p>
        <p>
          Joining The Lifted Initiative means becoming part of a pioneering
          force that is setting the benchmark for the future of decentralized
          technology. We're building a sophisticated, accessible, transparent,
          and scalable decentralized infrastructure, merging the practicality of
          Web2 with the groundbreaking potential of Web3 through our modular,
          interoperable framework.
        </p>
        <p>
          You'll be part of a team of seasoned professionals from both Web2 and
          Web3 backgrounds, in an environment that fosters professional growth
          and skill enhancement. At The Lifted Initiative, we're not just
          developing products; we're crafting experiences that empower users and
          redefine the digital landscape.
        </p>
      </div>
    </Container>
  )
}

export default Career
