import React from 'react'
import {
  Box,
  Fab,
  Fade,
  Grid,
  Link,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {Link as RouterLink} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
// import DiscordIcon from '../assets/discord.png'
import MediumIcon from '../assets/medium.png'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import manifestPowered from '../assets/manifest-powered.webp'

function ScrollTop(props) {
  const {children} = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{position: 'fixed', bottom: 16, right: 16}}
      >
        {children}
      </Box>
    </Fade>
  )
}

export default function Footer(props) {
  const theme = useTheme()
  const location = useLocation()

  return (
    <Box
      component="footer"
      sx={{mt: 5, py: 5, background: theme.palette.grey['200']}}
    >
      <Grid container spacing={1} wrap="wrap">
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Box
              component="a"
              href="https://discord.gg/QQXZTXsgqb"
              sx={{ml: 1}}
            >
              <Box component="img" src={DiscordIcon} sx={{width: 36}} />
            </Box> */}
            <Box
              component="a"
              href="https://twitter.com/liftedinit"
              sx={{ml: 1}}
            >
              <TwitterIcon
                style={{fontSize: 34, color: theme.palette.common.black}}
              />
            </Box>
            <Box
              component="a"
              href="https://www.linkedin.com/company/liftedinit/"
              sx={{ml: 1}}
            >
              <LinkedInIcon
                style={{fontSize: 34, color: theme.palette.common.black}}
              />
            </Box>
            <Box
              component="a"
              href="https://github.com/liftedinit/"
              sx={{ml: 1}}
            >
              <GitHubIcon
                style={{fontSize: 29, color: theme.palette.common.black}}
              />
            </Box>
            <Box
              component="a"
              href="https://medium.com/@TheLiftedInitiative"
              sx={{ml: 1}}
            >
              <Box component="img" src={MediumIcon} sx={{width: 40}} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Link
            component={RouterLink}
            to="/hosting"
            onClick={() => {
              if (location.pathname === '/hosting') {
                window.scrollTo(0, 0)
              }
            }}
            underline="none"
            sx={{display: 'block', my: 3, mx: 'auto', width: 200}}
          >
            <Box
              component="img"
              src={manifestPowered}
              style={{maxWidth: '100%'}}
              alt="Powered by The Manifest Network"
              sx={{boxShadow: '0 1px 9px rgba(0, 0, 0, 0.1)'}}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="p"
            variant="p"
            align="center"
            sx={{fontSize: 12}}
          >
            &copy; {new Date().getUTCFullYear()} The Lifted Initiative
          </Typography>
        </Grid>
      </Grid>

      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            background: theme.palette.common.black,
            '&:hover': {background: theme.palette.secondary.main},
          }}
        >
          <KeyboardArrowUpIcon sx={{color: theme.palette.common.white}} />
        </Fab>
      </ScrollTop>
    </Box>
  )
}
