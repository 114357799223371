import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>Front-End Developer</h2>
        <p>
          Join our team: The Lifted Initiative is at the vanguard of
          revolutionizing Web3 development, crafting the Manifest Network, an
          innovative Layer 0 platform that fuses the robustness of Web2 with the
          transformative potential of Web3 technologies. Our mission is to
          empower developers and businesses to seamlessly create cutting-edge,
          decentralized solutions, ushering in a new paradigm of market
          integration with decentralized technology.
        </p>
        <p>
          Your role: As a Mid-Level Front-End Developer at The Lifted
          Initiative, your role is pivotal in building and refining the user
          interface of our decentralized applications. You will leverage your
          expertise in modern front-end technologies and frameworks to deliver
          intuitive, responsive, and visually appealing web applications,
          ensuring an exceptional user experience that effectively communicates
          the power of our backend blockchain technologies.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Develop and maintain the front-end portion of our decentralized
            applications, crafting clean, efficient, and reusable code.
          </li>
          <li>
            Collaborate with UI/UX designers to transform visual designs into
            dynamic, responsive web applications, ensuring cross-browser and
            cross-platform compatibility.
          </li>
          <li>
            Implement complex web functionalities using modern
            JavaScript/TypeScript frameworks like React.js, Vue.js or Next.js,
            coupled with state management libraries like Redux or Vuex.
          </li>
          <li>
            Optimize applications for maximum speed and scalability, employing
            best practices in frontend performance optimization techniques and
            tools.
          </li>
          <li>
            Ensure code quality through rigorous testing, including unit,
            integration, and end-to-end tests, and maintain documentation for
            future reference and scalability.
          </li>
          <li>
            Stay abreast of the latest front-end development trends and
            technologies, continuously refining your skills and contributing to
            the team's knowledge base.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>
            Proven experience as a Front-End Developer, with a portfolio
            demonstrating proficiency in building and maintaining web
            applications.
          </li>
          <li>
            Strong expertise in modern HTML, CSS/SCSS, and JavaScript (ES6+),
            with a solid understanding of semantic markup, responsive design,
            and accessibility principles.
          </li>
          <li>
            Proficiency in at least one modern JavaScript/TypeScript framework
            (React.js, Vue.js, Angular, etc.), and familiarity with version
            control systems like Git.
          </li>
          <li>
            Experience with front-end build tools (Webpack, Rollup, Parcel) and
            familiarity with the Node.js ecosystem.
          </li>
          <li>
            A keen eye for detail and a passion for delivering seamless,
            user-centric interfaces that effectively communicate complex backend
            processes.
          </li>
          <li>
            Excellent problem-solving skills, a collaborative spirit, and the
            ability to thrive in a fast-paced, dynamic environment.
          </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          Joining The Lifted Initiative means becoming part of a pioneering
          force that is setting the benchmark for the future of decentralized
          technology. We're building a sophisticated, accessible, transparent,
          and scalable decentralized infrastructure, merging the practicality of
          Web2 with the groundbreaking potential of Web3 through our modular,
          interoperable framework.
        </p>
        <p>
          You'll be part of a team of seasoned professionals from both Web2 and
          Web3 backgrounds, in an environment that fosters professional growth
          and skill enhancement. At The Lifted Initiative, we're not just
          developing products; we're crafting experiences that empower users and
          redefine the digital landscape.
        </p>
      </div>
    </Container>
  )
}

export default Career
