import React, {useMemo} from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import {useParams} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import {styled} from '@mui/system'
import {useTheme} from '@mui/material/styles'
import {useNavigate} from 'react-router-dom'

import Hero from '../../components/Hero'
import Career1 from './Career1'
import Career2 from './Career2'
import Career3 from './Career3'
import Career4 from './Career4'
import Career5 from './Career5'
import Career6 from './Career6'
import Career7 from './Career7'
import Career8 from './Career8'
import Career9 from './Career9'

import imgLogo2 from '../../assets/logo2.png'

const careersMap = {
  'chief-marketing-officer': {
    title: 'Chief Marketing Officer',
    component: Career9,
  },
  'site-reliability-engineer': {
    title: 'Site Reliability Engineer',
    component: Career6,
  },
  'devops-engineer': {
    title: 'DevOps Engineer',
    component: Career7,
  },
  'systems-engineer': {
    title: 'Systems Engineer',
    component: Career8,
  },
  'ui-ux-designer': {
    title: 'UI/UX Designer',
    component: Career1,
  },
  'product-manager': {
    title: 'Product Manager',
    component: Career2,
  },
  'marketing-and-social-media-specialist': {
    title: 'Marketing and Social Media Specialist',
    component: Career3,
  },
  'mid-level-backend-developer': {
    title: 'Mid-Level Backend Developer (Cosmos/Rust)',
    component: Career4,
  },
  'front-end-developer': {
    title: 'Front-End Developer',
    component: Career5,
  },
}

const CareerLink = styled(HashLink)({
  display: 'inline-block',
  margin: '0 0 5px',
  padding: '5px 15px',
  textDecoration: 'none',
  color: '#63464f',
  borderRadius: '20px',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
})

export default function Careers() {
  const theme = useTheme()
  const navigate = useNavigate()
  const {careerId} = useParams()
  const CareerPage = useMemo(() => {
    const found = careersMap[careerId]

    if (found) {
      return found.component
    }
  }, [careerId])

  if (CareerPage)
    return (
      <Container maxWidth="md">
        <Container maxWidth="md">
          <Box
            sx={{
              display: 'flex',
              flexDirection: {xs: 'column', md: 'row'},
              alignItems: 'center',
              my: 5,
              justifyContent: 'space-between',
              borderBottom: '1px solid #eee',
              pb: 5,
            }}
          >
            <Box>
              <Button
                onClick={() => navigate('/careers')}
                variant="primary"
                sx={{
                  mr: {xs: 0, md: 3},
                  mb: {xs: 3, md: 0},
                  whiteSpace: 'nowrap',
                }}
              >
                Back to Careers
              </Button>
            </Box>
            <Box
              display={'flex'}
              alignContent={'center'}
              justifyContent={'center'}
              sx={{
                py: 0.8,
                px: 2,
                borderRadius: 20,
                background: theme.palette.grey['200'],
              }}
            >
              <Typography align="center">
                To apply please email your resume to{' '}
                <Box
                  component="a"
                  href="mailto:careers@liftedinit.org"
                  sx={{color: theme.palette.primary.main}}
                >
                  careers@liftedinit.org
                </Box>
              </Typography>
            </Box>
          </Box>
        </Container>
        <CareerPage />
      </Container>
    )

  return (
    <>
      <Hero>
        <Box
          component="img"
          sx={{mt: 10, display: 'block', mx: 'auto'}}
          alt="The Lifted Initiative"
          src={imgLogo2}
          className="fade_in float-y"
          onLoad={(ev) => {
            ev.target.classList.add('fade_in--loaded')
          }}
        />
        <Typography
          variant="h1"
          sx={{
            mt: 5,
            mb: 0,
            fontSize: {xs: '1.25rem', sm: '1.875rem', md: '3.125rem'},
          }}
        >
          Come work with us
        </Typography>

        <Box
          sx={{
            display: 'block',
            margin: 'auto',
            maxWidth: 'sm',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              my: 5,
              color: '#fff',
              mb: 0,
              fontSize: {xs: '1rem', md: '1.5rem'},
            }}
          >
            <Box component="span" sx={{ml: 1}}>
              We're looking for great people to join our growing team.
            </Box>
          </Typography>
        </Box>
      </Hero>

      <Container maxWidth="md">
        <Box component="section" sx={{my: 8}}>
          <Typography
            variant="h2"
            sx={{mb: 2, fontSize: {xs: '1.25rem', md: '1.75rem'}}}
          >
            Join our team
          </Typography>

          <Typography component="p">
            The Lifted Initiative is at the vanguard of revolutionizing Web3
            development, crafting the Manifest Network, an innovative Layer 0
            platform that fuses the robustness of Web2 with the transformative
            potential of Web3 technologies. Our mission is to empower developers
            and businesses to seamlessly create cutting-edge, decentralized
            solutions, ushering in a new paradigm of market integration with
            decentralized technology.
          </Typography>
        </Box>
      </Container>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            // px: {xs: 3, sm: 10},
            py: 8,
            background: '#eee',
          }}
        >
          <Container maxWidth="md">
            <Typography
              variant="h2"
              sx={{mb: 2, fontSize: {xs: '1.25rem', md: '1.75rem'}}}
            >
              Why Join The Lifted Initiative?
            </Typography>
            <Typography component="p">
              Joining The Lifted Initiative means becoming part of a pioneering
              force that is setting the benchmark for the future of
              decentralized technology. We're building a sophisticated,
              accessible, transparent, and scalable decentralized
              infrastructure, merging the practicality of Web2 with the
              groundbreaking potential of Web3 through our modular,
              interoperable framework.
            </Typography>
          </Container>
        </Box>
      </Container>

      <Container maxWidth="md">
        <Box component="section" sx={{my: 5}}>
          {Object.keys(careersMap).map((key) => {
            return (
              <Box key={key}>
                <CareerLink to={`/careers/${key}`}>
                  <Typography component="p">{careersMap[key].title}</Typography>
                </CareerLink>
              </Box>
            )
          })}
        </Box>
      </Container>
    </>
  )
}
