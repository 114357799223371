import React from 'react'
import {Container} from '@mui/material'

const Career = () => {
  return (
    <Container maxWidth="md">
      <div>
        <h2>Marketing and Social Media Specialist</h2>
        <p>
          Join our team: Step into the cutting-edge world of The Lifted
          Initiative, where we're streamlining the complexities of Web3
          development. As the creators of the Manifest Network, a revolutionary
          Layer 0 network, we're merging the capabilities of Web2 and Web3
          technologies. Our mission is to empower developers and businesses to
          effortlessly craft bespoke, future-ready solutions, connecting today's
          markets with the limitless potential of decentralized technology.
        </p>
        <p>
          Your role: As a Marketing and Social Media Specialist at The Lifted
          Initiative, you will be at the heart of our communication and branding
          efforts. Your creativity and strategic thinking will help narrate our
          story, showcasing the simplicity, power, and innovation of our Web3
          solutions. You'll be the voice and the ear of our brand, engaging with
          our diverse and growing community across various platforms.
        </p>
        <p>What you'll do:</p>
        <ul>
          <li>
            Develop and execute innovative marketing strategies to promote the
            Manifold Framework and other Lifted products, highlighting the
            seamless integration of complex Web2 and Web3 technologies.
          </li>
          <li>
            Manage and grow our presence on social media platforms, engaging
            with our community, and fostering relationships with influencers,
            thought leaders, and partners in the tech space.
          </li>
          <li>
            Create compelling, informative content that resonates with our
            target audiences, including blog posts, social media updates,
            newsletters, and press releases.
          </li>
          <li>
            Collaborate with the product and design teams to ensure consistent
            and effective branding across all communication channels.
          </li>
          <li>
            Monitor, analyze, and report on the performance of marketing
            initiatives, using insights to drive better engagement and results.
          </li>
        </ul>
        <p>What we’re looking for:</p>
        <ul>
          <li>
            Proven experience in marketing and social media management,
            preferably within the technology sector.
          </li>
          <li>
            A passion for storytelling, with a knack for writing engaging
            content and a keen eye for visual branding.
          </li>
          <li>
            Familiarity with the Web3 space and enthusiasm for decentralized
            technology and its potential.
          </li>
          <li>
            Strong understanding of social media platforms, SEO, content
            marketing, and analytics tools.
          </li>
          <li>
            Excellent communication skills, both written and verbal, and the
            ability to collaborate effectively with a diverse, dynamic team.
          </li>
          <li>
            A proactive, creative mindset, with a drive for innovation,
            usability, and customer engagement.
          </li>
        </ul>
        <p>Why The Lifted Initiative?</p>
        <p>
          By joining The Lifted Initiative, you're not just becoming part of a
          company; you're aligning with a visionary force reshaping the digital
          world. We're constructing a decentralized infrastructure that's
          accessible, transparent, and scalable, combining the best practices of
          Web2 with the groundbreaking potential of Web3. Our modular,
          interoperable framework enables rapid project launches, paving the way
          for widespread technology adoption.
        </p>
        <p>
          You'll collaborate with a team of industry veterans from the Web2 and
          Web3 realms, all committed to nurturing your growth and unlocking your
          full potential. At The Lifted Initiative, innovation is at our core,
          and the user always comes first.
        </p>
      </div>
    </Container>
  )
}

export default Career
